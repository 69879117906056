<div class="p-3 show-claims-modal" rlKonami (onEntered)="unlockDevTools(nav, 'dev-tools')">
	<div class="d-flex">
		<h2 class="flex-grow-1">Debug</h2>
		<div>
			<button class="btn btn-close" (click)="close()" aria-label="Close" role="button"><i class="far fa-times"></i></button>
		</div>
	</div>
	<nav ngbNav #nav="ngbNav" class="nav-tabs" [destroyOnHide]="false">
		<ng-container ngbNavItem>
			<a ngbNavLink>Version</a>
			<ng-template ngbNavContent>
				<rl-version-info></rl-version-info>
			</ng-template>
		</ng-container>
		<ng-container ngbNavItem>
			<a ngbNavLink>Session</a>
			<ng-template ngbNavContent>
				<div class="d-flex">
					<div ngbNav #sessionNav="ngbNav" class="nav-pills flex-column" orientation="vertical">
						<ng-container ngbNavItem="user">
							<button ngbNavLink>User</button>
							<ng-template ngbNavContent>
								<h3 class="m-0">
									User Details
								</h3>
								<table class="table table-striped mb-3">
									<tbody>
										<tr>
											<th>Name</th>
											<td>
												{{displayName$ | async}}
											</td>
										</tr>
										<tr>
											<th>Email</th>
											<td>
												{{userName$ | async}}
											</td>
										</tr>
										<tr>
											<th>User ID</th>
											<td>{{userId$ | async}}</td>
										</tr>
										<tr>
											<th>Is Super User</th>
											<td>{{isSuperAdmin$ | async}}</td>
										</tr>
									</tbody>
								</table>
							</ng-template>
						</ng-container>
						<ng-container ngbNavItem="acls">
							<button ngbNavLink>Access</button>
							<ng-template ngbNavContent>
								<rl-user-acl-info></rl-user-acl-info>
							</ng-template>
						</ng-container>
						<ng-container ngbNavItem="token">
							<button ngbNavLink>Token</button>
							<ng-template ngbNavContent>
								<h3 class="m-0">
									JSON Web Token
								</h3>
								<pre class="my-1 p-1 bg-light border rounded">{{ accountJson | json }}</pre>
							</ng-template>
						</ng-container>
					</div>
					<div [ngbNavOutlet]="sessionNav" class="ms-4"></div>
				</div>
			</ng-template>
		</ng-container>
		<ng-container ngbNavItem>
			<a ngbNavLink>SOLR & DB Counts</a>
			<ng-template ngbNavContent>
				<table *ngIf="!loadingSolrCounts;else loading" class="table table-sm mt-3">
					<tbody>
						<tr class="header">
							<td>Char Type</td>
							<td>DB Count</td>
							<td>SOLR Count</td>
						</tr>
						<tr *ngFor="let cc of countComparison" [ngClass]="{'mismatch': cc.dbCount !== cc.solrCount}">
							<td>{{cc.charTypeDisplay}}</td>
							<td>{{cc.dbCount}}</td>
							<td>{{cc.solrCount}}</td>
						</tr>
					</tbody>
				</table>
			</ng-template>
		</ng-container>
		<ng-container ngbNavItem>
			<a ngbNavLink>Datamart & DB Counts</a>
			<ng-template ngbNavContent>
				<rl-datamart-record-count></rl-datamart-record-count>
			</ng-template>
		</ng-container>
		<ng-container ngbNavItem>
			<a ngbNavLink>Date Math</a>
			<ng-template ngbNavContent>
				<h4>Orphaned Date Math Relationships
					<span *ngIf="orphanedDateMathRels">({{ orphanedDateMathRels.length }} found)</span>
					<button class="btn btn-sm btn-primary ms-2" [disabled]="loadingOrphanedRels" (click)="fetchOrphanedDateMathRels()">
						<i *ngIf="loadingOrphanedRels" class="far fa-spinner fa-spin me-2"></i>
						Fetch DM Rels
					</button>
				</h4>
				<table *ngIf="orphanedDateMathRels" class="table table-striped">
					<thead>
						<tr>
							<th>Parent Record</th>
							<th>RecCharId</th>
							<th>ParentRecId</th>
							<th>ParentRecCharId</th>
							<th>Reason</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let rel of orphanedDateMathRels">
							<td>
								<a [routerLink]="linkHelper.go(rel.parentCharTypeID, rel.parentRecID)" target="_blank">
									{{ rel.parentCharTypeID | charTypeName }} Record {{ rel.parentRecID }}
								</a>
							</td>
							<td>{{ rel.recCharID }}</td>
							<td>{{ rel.parentRecID }}</td>
							<td>{{ rel.parentRecCharID }}</td>
							<td>
								<ng-container *ngIf="rel.parentRecCharID >= 0">
									<b>Parent record characteristic {{rel.parentRecCharID}} does not exist.</b>
									<pre class="m-2">
SELECT * FROM REC_XCHR_AUDIT
WHERE DIV_ID = {{rel.divID}} AND REC_ID = {{rel.parentRecID}} AND XCHR_TYP_ID = {{rel.parentCharTypeID}} and REC_XCHR_ID = {{rel.parentRecCharID}}
								</pre>
								</ng-container>
								<ng-container *ngIf="rel.parentRecCharID < 0">
									<b>Negative parent record characteristic {{rel.parentRecCharID}} found.</b>
								</ng-container>
							</td>
						</tr>
						<tr *ngIf="orphanedDateMathRels.length === 0" class="text-center text-muted">
							<td colspan="5">
								<h5 class="m-2">
									<i class="fas fa-glass-cheers me-2"></i> No Orphaned Rels
								</h5>
							</td>
						</tr>
					</tbody>
					<small *ngIf="orphanedDateMathRels.length === 100">*only the top 100 orphaned rels are shown</small>
				</table>
			</ng-template>
		</ng-container>
		<ng-container ngbNavItem>
			<a ngbNavLink>Alliant Information</a>
			<ng-template ngbNavContent>
				<rl-about-alliant [loadingTemplate]="loading"></rl-about-alliant>
			</ng-template>
		</ng-container>
		<ng-container *ngIf="devToolsUnlocked" [ngbNavItem]="'dev-tools'">
			<a ngbNavLink>Dev Tools</a>
			<ng-template ngbNavContent>
				<div class="container-fluid">
					<div class="row">
						<div class="col-4">
							<div class="card border h-100">
								<div class="card-body">
									<h5 class="card-title">Test HTTP Status Codes</h5>
									<h6 class="card-subtitle mb-2 text-muted">
										Throws a server side exception so that we can test logging + error handling.
									</h6>
									<div class="container-fluid">
										<div class="row">
											<div class="col-8 ps-0">
												<select class="form-select me-2" [formControl]="statusCodeControl">
													<option [ngValue]="status[0]" *ngFor="let status of statusCodes">
														{{status[0]}} {{ status[1] }}
													</option>
												</select>
											</div>
											<div class="col-4">
												<button class="btn btn-info btn-sm" (click)="throwApiException()" [disabled]="isTestingStatusCode">
													Run Test
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-4">
							<div class="card border h-100">
								<div class="card-body">
									<h5 class="card-title">Test Sentry</h5>
									<h6 class="card-subtitle mb-2 text-muted">
										Throws a client side exception so that we can test Sentry.
									</h6>
									<div class="card-text">
										<button class="btn btn-info btn-sm" (click)="throwError()" [disabled]="isTestingStatusCode">
											Throw Error
										</button>
									</div>
								</div>
							</div>
						</div>
						<div class="col-4">
							<div class="card border h-100">
								<div class="card-body">
									<h5 class="card-title">Run Alert Notification Processing Job</h5>
									<h6 class="card-subtitle mb-2 text-muted">
										Runs the recurring Alert Notification Processing Job.
									</h6>
									<div class="card-text">
										<button class="btn btn-info btn-sm" (click)="runAlertNotifJob()">
											Run Job
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="row mt-3">
						<div class="col-4">
							<div class="card border">
								<div class="card-body">
									<h5 class="card-title">Run Exchange Rate Update Job</h5>
									<h6 class="card-subtitle mb-2 text-muted">
										Run the recurring Exchange Rate Nightly Job. Feature flag must be enabled:
										updateExchangeRateNightlyJob
									</h6>
									<div class="card-text">
										<div class="row">
											<div class="col-8">
												<rl-text-input [(ngModel)]="exchangeRateDealId" class="flex-fill"
													placeholder="Specific Deal ID to run on (leave blank to run for all deals in div)"></rl-text-input>
											</div>
											<div class="col-4">
												<button class="btn btn-info btn-sm" (click)="runExchangeRateJob()" [disabled]="isTestingStatusCode"
													data-cy="RunJobButton">
													Run Job {{exchangeRateDealId ? 'For Deal ' + exchangeRateDealId :
													''}}
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</ng-template>
		</ng-container>
	</nav>
	<div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>

<ng-template #loading>
	<p class="text-center">
		<rl-loader></rl-loader>
	</p>
</ng-template>