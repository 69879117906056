import { PortalModule } from "@angular/cdk/portal";
import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { Router } from "@angular/router";
import { NgbAccordionDirective } from "@ng-bootstrap/ng-bootstrap";
import * as Sentry from "@sentry/angular-ivy";
import { RecentDivsComponent } from "app/rightsline-app/components/header/profile-dropdown/recent-divs/recent-divs.component";
import { NgxPendoModule } from "ngx-pendo";
import { JobModalComponent } from "rl-common/components/job-modal/job-modal.component";
import { SessionInitResult, SessionService } from "rl-common/services/session.service";
import { roaringLibraryInitialize } from "roaring-wasm";
import { Observable, of } from "rxjs";
import { timeoutWith } from "rxjs/operators";
import { RLCommonModule } from "../common/rl-common.module";
import { PendoService } from "../common/services/pendo/pendo.service";
import { DASHBOARD_COMPONENTS, WIDGETS } from "./components/dashboard";
import { TemplatesBySelectedStatePipe } from "./components/deals-by-status/selected-templates.pipe";
import { DocusignGrantComponent } from "./components/docusign-grant/docusign-grant.component";
import { ForgotPasswordComponent } from "./components/forgot-password/forgot-password.component";
import { GridTestComponent } from "./components/grid-test.component";
import { GridTest2Component } from "./components/grid-test2.component";
import { GridTest3Component } from "./components/grid-test3.component";
import { GridTest4Component } from "./components/grid-test4.component";
import { GridTest5Component } from "./components/grid-test5.component";
import { GrowlNotificationsComponent } from "./components/growl-notifications/growl-notifications.component";
import { HeaderFavoritesComponent } from "./components/header/header-favorites/header-favorites.component";
import { HeaderNotificationsComponent } from "./components/header/header-notifications/header-notifications.component";
import { RlHeaderComponent } from "./components/header/header.component";
import { LogoComponent } from "./components/header/logo/logo.component";
import { ProfileDropdownComponent } from "./components/header/profile-dropdown/profile-dropdown.component";
import { SelectDivControlComponent } from "./components/header/profile-dropdown/select-div-control/select-div-control.component";
import { RefreshPromptComponent } from "./components/header/refresh-prompt/refresh-prompt.component";
import { UniversalSearchResultsComponent } from "./components/header/universal-search-results/universal-search-results.component";
import { UniversalSearchComponent } from "./components/header/universal-search/universal-search.component";
import { HomeComponent } from "./components/home.component";
import { LoginStepComponent } from "./components/login/login-step/login-step.component";
import { LoginComponent } from "./components/login/login.component";
import { SsoLoginButtonComponent } from "./components/login/sso-login-button/sso-login-button.component";
import { AboutAlliantComponent } from "./components/modals/about-alliant/about-alliant.component";
import { DebugModalComponent } from "./components/modals/debug-modal/debug-modal.component";
import { UserAclInfoComponent } from "./components/modals/user-acl-info/user-acl-info.component";
import { VersionInfoComponent } from "./components/modals/version-info/version-info.component";
import { ProgressBarComponent } from "./components/progress-bar/progress-bar.component";
import { ResetPasswordComponent } from "./components/reset-password/reset-password.component";
import { RLAppComponent } from "./components/rl-app.component";
import { SideNavigationComponent } from "./components/side-navigation/side-navigation.component";
import { SsoTokenComponent } from "./components/sso-token/sso-token.component";
import { DatamartRecordCountComponent } from "./components/statistics/datamart-record-count/datamart-record-count.component";
import { WorkflowActionsExecuteComponent } from "./components/workflow-actions-execute/workflow-actions-execute.component";
import { KonamiDirective } from "./directives/konami.directive";
import { registerLocales } from "./rl-app.locales";
import { AppRoutingModule } from "./rl-app.routing";
import { GlobalErrorHandler } from "./utils/global-error-handler";

registerLocales();
const tokenRe = /\/sso\/([a-f0-9]{8}\-?[a-f0-9]{4}\-?[a-f0-9]{4}\-?[a-f0-9]{4}\-?[a-f0-9]{12})/i;

export function initializeApp(sessionService: SessionService, pendoService: PendoService) {
	return async (): Promise<any> => {
		if (tokenRe.exec(window.location.pathname) != null) {
			sessionService.clearSession();
		}

		const redirectToLogin = () => {
			const location = window.location;
			// prevent infinite loops
			if (location.pathname !== "/login") {
				location.href = `/login?returnUrl=${encodeURIComponent(location.pathname + location.search + location.hash)}`;
			}
		};

		if (window.BroadcastChannel !== undefined) {
			const refreshChannel = new BroadcastChannel("refresh");
			refreshChannel.onmessage = ($evt) => {
				window.location.reload();
			};
		}

		try {
			const initResult = await sessionService.initialize();
			switch (initResult) {
				case SessionInitResult.NoSession:
					console.log("no session found");
					break;
				case SessionInitResult.InvalidSession:
					console.log("invalid existing session");
					sessionService.clearSession();
					redirectToLogin();
					break;
				case SessionInitResult.ValidSession:
					console.log("valid session");
					break;
			}
		} catch (err) {
			console.log(err);
			sessionService.clearSession();
		}

		// must do this to support the wasm built library
		await roaringLibraryInitialize();

		if (window["onRLAppLoad"]) {
			const obs = new Observable<void>((observer) => {
				window["onRLAppLoad"](() => {
					observer.next(null);
					observer.complete();
					console.timeEnd("Loading");
					try {
						const diff = Math.round((performance.now() - window["rlAppStartTime"]) / 1000);
						pendoService.trackEvent("Load Time", { message: `${diff} seconds` });
					} catch (error) {
						console.error(error);
					}
				});
			});
			// give callback up to 500ms to return
			await obs.pipe(timeoutWith(500, of(null))).toPromise();
		}
	};
}

@NgModule({
	declarations: [
		RLAppComponent,
		HomeComponent,
		DocusignGrantComponent,
		LoginComponent,
		GridTestComponent,
		GridTest2Component,
		GridTest3Component,
		GridTest4Component,
		GridTest5Component,
		RlHeaderComponent,
		SideNavigationComponent,
		KonamiDirective,
		JobModalComponent,
		DebugModalComponent,
		VersionInfoComponent,
		UserAclInfoComponent,
		...WIDGETS,
		ProfileDropdownComponent,
		RecentDivsComponent,
		ForgotPasswordComponent,
		ResetPasswordComponent,
		HeaderNotificationsComponent,
		TemplatesBySelectedStatePipe,
		...DASHBOARD_COMPONENTS,
		HeaderFavoritesComponent,
		SsoTokenComponent,
		SelectDivControlComponent,
		LoginStepComponent,
		SsoLoginButtonComponent,
		WorkflowActionsExecuteComponent,
		ProgressBarComponent,
		GrowlNotificationsComponent,
		UniversalSearchComponent,
		UniversalSearchResultsComponent,
		DatamartRecordCountComponent,
		RefreshPromptComponent,
		LogoComponent,
		AboutAlliantComponent
	],
	exports: [
		ProfileDropdownComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		FormsModule,
		ReactiveFormsModule,
		RLCommonModule.forRoot(),
		AppRoutingModule,
		PortalModule,
		NgxPendoModule.forRoot({
			pendoApiKey: "2caaa24c-28b3-4c68-5328-364d2d84ee95",
			pendoIdFormatter: (value: any) => value.toString().toLowerCase()
		}),
		NgbAccordionDirective
	],
	providers: [
		{
			provide: ErrorHandler,
			useClass: GlobalErrorHandler
		},
		{
			provide: Sentry.TraceService,
			deps: [Router],
		},
		{
			provide: APP_INITIALIZER,
			useFactory: initializeApp,
			deps: [SessionService, Sentry.TraceService],
			multi: true
		}
	],
	bootstrap: [RLAppComponent]
})
export class RLAppModule { }
