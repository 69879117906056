<h3 class="mt-0">
	Access Control Values ({{acls.length}})
</h3>
<table class="table table-sm table-striped mt-3">
	<thead>
		<tr>
			<th>URI</th>
			<th class="text-center">Read</th>
			<th class="text-center">Write</th>
			<th class="text-center">Create</th>
			<th class="text-center">Delete</th>
		</tr>
	</thead>
	<tbody>
		<tr *ngFor="let acl of acls">
			<td>{{acl.uri}}</td>
			<td class="access-cell" [class]="acl.hasReadAccess ? 'success' : 'danger'">
				<i class="far" [class]="acl.hasReadAccess ? 'fa-check' : 'fa-times'"></i>
			</td>
			<td class="access-cell" [class]="acl.hasWriteAccess ? 'success' : 'danger'">
				<i class="far" [class]="acl.hasWriteAccess ? 'fa-check' : 'fa-times'"></i>
			</td>
			<td class="access-cell" [class]="acl.hasCreateAccess ? 'success' : 'danger'">
				<i class="far" [class]="acl.hasCreateAccess ? 'fa-check' : 'fa-times'"></i>
			</td>
			<td class="access-cell" [class]="acl.hasDeleteAccess ? 'success' : 'danger'">
				<i class="far" [class]="acl.hasDeleteAccess ? 'fa-check' : 'fa-times'"></i>
			</td>
		</tr>
	</tbody>
</table>